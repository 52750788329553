import { connect } from 'react-redux';
import PeopleList from 'components/PeopleList';
import { searchCollaboratorsInProject } from 'pages/ProjectContainer/ManagePeople/actions';
import { listReviewers } from '../actions';
import {
  invitedEntitiesForItemIdSelector,
  invitedTotalForItemIdSelector,
} from '../selectors';

function mapStateToProps(state) {
  const invitedEntities = invitedEntitiesForItemIdSelector(state);
  const invitedTotal = invitedTotalForItemIdSelector(state);
  return {
    // personIds requires an array, so if undefined return an empty array.
    personIds: invitedEntities || [],
    // the undefined value is useful to identify when the content has NOT been loaded.
    // we thus do not show the count until we get a value even if it means an empty array.
    totalCount: invitedTotal,
  };
}

function mapDispatchToProps(dispatch, { projectId, presentationId }) {
  return {
    filterPeople: (query) =>
      dispatch(searchCollaboratorsInProject(projectId, query)),
    fetchPeople: (page) => dispatch(listReviewers(page, presentationId)),
  };
}

export const testExports = { mapStateToProps, mapDispatchToProps };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleList);
