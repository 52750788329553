import { xor } from 'lodash';
import { type as assetTypes } from '@frameio/core/src/assets/helpers/constants';
import REVIEW_LINK_EDITOR from 'components/ReviewLinkEditor/actions';
import { CONTEXT_MENU } from 'components/ContextMenuManager/actions';
import { folderAncestorsForIdSelector } from 'selectors/folders';
import {
  currentProjectSelector,
  folderTreeForProjectIdSelector,
} from 'selectors/projects';
import {
  getAncestorsFromProjectTree,
  getReviewLinkAssetDataSelector,
} from './selectors';

export const folderSelectionStatus = {
  INDETERMINATE: 'indeterminate',
  SELECTED: 'selected',
};

const INITIAL_STATE = {
  id: null,
  assets: undefined,
  folderIds: {},
  invitedIds: undefined,
  invitedTotal: undefined,
};

// Local folder ancestors cache
const folderAncestorsCache = {};

function setFolderAncestorsCacheKeyValue(folderId, rootState) {
  const { id: projectId } = currentProjectSelector(rootState);
  const { tree } = folderTreeForProjectIdSelector(rootState, { projectId });
  folderAncestorsCache[folderId] =
    getAncestorsFromProjectTree(tree, folderId) || [];
}

function getFolderAncestors(folderId, rootState) {
  let ancestors =
    folderAncestorsCache[folderId] ||
    folderAncestorsForIdSelector(rootState, {
      folderId,
    });

  /*
    If folder ancestor data isn't present in either the `folderAncestors` redux
    slice or in the local `folderAncestorsCache`, recursively derive its ancestors
    using the associated project folder tree.
  */
  if (!ancestors && !folderAncestorsCache[folderId]) {
    setFolderAncestorsCacheKeyValue(folderId, rootState);
    ancestors = folderAncestorsCache[folderId];
  }

  return ancestors;
}

/**
 * Helper function to set _selected_ folder statuses for folder assets that have been
 * selected for sharing.
 * @param {string} folderIds - Current folderIds selection statuses.
 * @param {Object[]} assets - Assets currently selected for sharing.
 * @returns {Object} Updated folderIds.
 */
function setSelectedFolderStatusFromAssets(folderIds = {}, assets = []) {
  return assets
    .filter(({ type }) => type === assetTypes.FOLDER)
    .reduce(
      (obj, selectedAsset) => ({
        ...obj,
        [selectedAsset.id]: folderSelectionStatus.SELECTED,
      }),
      folderIds
    );
}

/**
 * Helper function to remove assets from the review link editor `assets` slice that
 * are descendants of a given folder id.
 * @param {string} folderId - Folder id whose descendant assets to be removed.
 * @param {Object[]} assets - Assets currently selected for sharing.
 * @param {Object} rootState - Redux root state tree.
 * @returns {Object[]} Updated assets array.
 */
function removeFolderIdDescendantAssets(folderId, assets, rootState) {
  // Track removed folder ids to avoid unnecessary ancestor status recalculation
  // for assets that are children of the same removed folder id(s).
  const removedFolderIds = [];

  let i = 0;

  // Iteratively remove any selected assets that are descendants of the given folder id.
  while (i < assets.length) {
    const { parentId: assetParentId } = assets[i];
    const ancestors = getFolderAncestors(assetParentId, rootState);

    if (removedFolderIds.includes(assetParentId)) {
      assets.splice(i, 1);
    } else if (ancestors.includes(folderId)) {
      assets.splice(i, 1);
      removedFolderIds.push(assetParentId);
    } else {
      i += 1;
    }
  }

  return assets;
}

/**
 * Helper function to remove assets from the review link editor `assets` slice that
 * are descendants of _any_ folders that have since been selected for sharing (and
 * are now present in the `assets` slice).
 * @param {Object[]} assets - Assets currently selected for sharing.
 * @param {Object} rootState - Redux root state tree.
 * @returns {Object[]} Updated assets array.
 */
function removeSelectedFolderDescendantAssets(assets, rootState) {
  const selectedFolderIds = assets
    .filter(({ type }) => type === assetTypes.FOLDER)
    .map(({ id }) => id);

  selectedFolderIds.forEach((folderId) => {
    // eslint-disable-next-line no-param-reassign
    assets = removeFolderIdDescendantAssets(folderId, assets, rootState);
  });

  return assets;
}

/**
 * Helper function to update the review link editor `folderIds` slice by setting
 * the selection status for a given folderId's _ancestor_ folders.
 * @param {string} folderId - Folder id.
 * @param {Object} folderIds - Current review link editor folderIds slice.
 * @param {Object} rootState - Redux root state tree.
 * @param {string} selectionStatus - Folder selection status to set on all ancestors.
 * @returns {Object} Updated review link editor folderIds slice.
 */
function setFolderAncestorSelectionStatus(
  folderId,
  folderIds,
  rootState,
  selectionStatus
) {
  // Clone the current folderIds slice
  const updatedFolderIds = { ...folderIds };

  const folderAncestors = getFolderAncestors(folderId, rootState);

  folderAncestors.forEach((folderAncestor) => {
    updatedFolderIds[folderAncestor] = selectionStatus;
  });
  return updatedFolderIds;
}

/**
 * Helper function to update the review link editor `assets` and `folderIds` slices when selecting or
 * de-selecting an asset.
 * @param {string} assetIds - Selected assetIds action payload.
 * @param {Object} state - Current review link editor redux state.
 * @param {Object} rootState - Redux root state tree.
 * @param {boolean} [resetSelectedAssets] - Whether or not a review link's selected assets
 * should be reset/reconciled; NOTE: Currently this is only necessary when a `Select All`
 * action is dispatched, which may necessitate the removal of previously selected descendant
 * asset(s) of newly selected folders.
 * @returns {Object} {updatedAssets, updatedFolderIds} - Updated asset data and folderIds.
 */
function getUpdatedReviewLinkAssetData(
  assetIds,
  state,
  rootState,
  resetSelectedAssets
) {
  const { assets = [], folderIds } = state;
  let assetsToAdd;
  let updatedAssets = [];
  let updatedFolderIds = {};

  // If no assets are selected for sharing, return early.
  if (assetIds.length === 0) {
    return { updatedAssets, updatedFolderIds };
  }

  const updatedAssetIds = assets.map(({ id }) => id);

  // Derive diff between payload assets and review link editor assets in store
  const assetIdsDiff = xor(assetIds, updatedAssetIds);

  if (assetIds.length > updatedAssetIds.length) {
    // Add asset(s) to review link editor slice
    assetsToAdd = getReviewLinkAssetDataSelector(rootState, {
      assetIds: assetIdsDiff,
    });
    updatedFolderIds = { ...folderIds };
    updatedAssets = [...assets, ...assetsToAdd];
  } else {
    // Remove asset(s) from review link editor slice
    updatedAssets = assets.filter(({ id }) => !assetIdsDiff.includes(id));
  }

  if (resetSelectedAssets) {
    updatedFolderIds = {};
    updatedAssets = removeSelectedFolderDescendantAssets(
      updatedAssets,
      rootState
    );
  }

  // Reconcile selected folders after asset reconciliation
  updatedFolderIds = setSelectedFolderStatusFromAssets(
    updatedFolderIds,
    updatedAssets
  );

  /*
    After deriving the updated `assets` slice, ensure the `folderIds` slice reflects the expected
    _indeterminate_ folder(s) selection. NOTE: Folder _selected_ status is handled on the selection
    toggle action itself (and not as a side effect here). If new asset(s) have been selected for
    sharing, we only need to set the folder ancestor status for those assets. If assets have been
    de-selected from sharing, we derive the folder ancestor status for the remaining selected
    assets for simplicity.
    TODO(Anna): Re-visit this logic using the full project asset tree.
  */
  (assetsToAdd || updatedAssets).forEach(({ parentId: folderId }) => {
    if (!updatedFolderIds[folderId]) {
      // Update the asset's ancestor folders to _indeterminate_
      updatedFolderIds = setFolderAncestorSelectionStatus(
        folderId,
        updatedFolderIds,
        rootState,
        folderSelectionStatus.INDETERMINATE
      );
    }
  });

  return { updatedAssets, updatedFolderIds };
}

/**
 * Helper function to update the review link editor `assets` and `folderIds` slices when de-selecting
 * an indeterminately selected folder. This requires removing any existing assets that are descendants
 * of the de-selected folder, and resetting status in the folder tree.
 * @param {string} deselectedFolderId - De-selected folder id.
 * @param {Object} state - Current review link editor redux state.
 * @param {Object} rootState - Redux root state tree.
 * @returns {Object} {updatedAssets, updatedFolderIds} - Updated asset data and folderIds.
 */
function deselectIndeterminateFolderAssets(
  deselectedFolderId,
  state,
  rootState
) {
  const { assets } = state;
  let updatedAssets = [...assets];
  let updatedFolderIds = {};

  updatedAssets = removeFolderIdDescendantAssets(
    deselectedFolderId,
    updatedAssets,
    rootState
  );

  // Reset _indeterminate_ folder statuses
  updatedAssets.forEach(({ parentId: folderId }) => {
    if (!updatedFolderIds[folderId]) {
      updatedFolderIds = setFolderAncestorSelectionStatus(
        folderId,
        updatedFolderIds,
        rootState,
        folderSelectionStatus.INDETERMINATE
      );
    }
  });

  return { updatedAssets, updatedFolderIds };
}

const reviewLinkEditorReducer = (state = INITIAL_STATE, action, rootState) => {
  switch (action.type) {
    // on close wipe the list of invited users
    // otherwise you will see that list/number on open of a new modal.
    case CONTEXT_MENU.CLOSE:
      return {
        ...state,
        invitedIds: INITIAL_STATE.invitedIds,
        invitedTotal: undefined,
      };
    case REVIEW_LINK_EDITOR.SELECT_ITEMS:
    case REVIEW_LINK_EDITOR.TOGGLE_SELECTION_MODE: {
      const { assets, resetSelectedAssets } = action.payload;
      let updatedAssets = assets;
      let updatedFolderIds = state.folderIds;

      if (assets) {
        ({ updatedAssets, updatedFolderIds } = getUpdatedReviewLinkAssetData(
          assets,
          state,
          rootState,
          resetSelectedAssets
        ));
      }

      return {
        ...state,
        assets: updatedAssets,
        folderIds: {
          ...updatedFolderIds,
        },
      };
    }

    case REVIEW_LINK_EDITOR.SET_CURRENT_EDITOR_ID: {
      const { id } = action.payload;
      return {
        ...state,
        id,
      };
    }

    case REVIEW_LINK_EDITOR.STORE_INVITED_IDS: {
      const { ids: invitedIds, invitedTotal } = action.payload;
      return {
        ...state,
        invitedIds,
        invitedTotal,
      };
    }

    case REVIEW_LINK_EDITOR.SET_FOLDER_SELECTION_STATUS: {
      const { folderId, selectionStatus: currSelectionStatus } = action.payload;
      const { assets, folderIds } = state;
      const prevSelectionStatus = folderIds[folderId];
      let updatedAssets = assets;
      let updatedFolderIds = state.folderIds;

      // If an indeterminately selected folder is deselected
      if (
        prevSelectionStatus === folderSelectionStatus.INDETERMINATE &&
        !currSelectionStatus
      ) {
        ({
          updatedAssets,
          updatedFolderIds,
        } = deselectIndeterminateFolderAssets(folderId, state, rootState));
      }

      return {
        ...state,
        folderIds: {
          ...updatedFolderIds,
          [folderId]: currSelectionStatus,
        },
        assets: updatedAssets,
      };
    }

    case REVIEW_LINK_EDITOR.CANCEL_SELECTION:
    case REVIEW_LINK_EDITOR.CONFIRM_FROM_SELECTION: {
      return {
        ...state,
        folderIds: {},
      };
    }

    default:
      return state;
  }
};

export default reviewLinkEditorReducer;
export const testExports = {
  INITIAL_STATE,
};
