import React from 'react';
import {
  isDownloadDisabledCheck,
  resolutionToResolutionField,
} from 'utils/downloads';
import { MenuButton } from '@frameio/vapor';
import { trackButtonClick } from 'analytics';

/**
 * Generates menu buttons for each proxy download resolution
 */
// eslint-disable-next-line import/prefer-default-export
export const getProxyDownloadButtons = ({
  resolutionsThatHaveDownloads,
  downloadAsset,
  file,
  isForensicFallbackEnabled,
  trackingPage,
  trackingPosition,
}) => {
  const {
    transcodes: { original_width: width, original_height: height } = {},
  } = file;

  return resolutionsThatHaveDownloads
    .sort((a, b) => b - a)
    .map((resolution) => {
      const isVertical = height > width;
      const aspectRatio = isVertical ? height / width : width / height;
      const text = isVertical
        ? `MP4 ${resolution}x${Math.round(resolution * aspectRatio)}`
        : `MP4 ${Math.round(resolution * aspectRatio)}x${resolution}`;
      const resKey = resolutionToResolutionField[resolution];
      return (
        <MenuButton
          disabled={isDownloadDisabledCheck(
            file,
            isForensicFallbackEnabled,
            resolution
          )}
          key={resolution}
          aria-label={`Proxy ${resolution}`}
          onSelect={() => {
            downloadAsset(file.id, resolution);
            trackButtonClick(
              `downloaded ${resKey}`,
              trackingPage,
              trackingPosition
            );
          }}
        >
          {text}
        </MenuButton>
      );
    });
};
