import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const PRESENTATION_EDITOR = generateActionTypes('PRESENTATION_EDITOR', [
  'EDIT',
  'OPEN',
  'INVITE_USERS',
  'DELETE_INVITEE',
  'LIST_REVIEWERS_FOR_PRESENTATION_ID',
  'SELECT_ASSETS',
  'SET_CURRENT_EDITOR_ID',
  'SET_DEFAULT_TEAM_SESSION_WATERMARK_ID',
  'SHARE_ASSETS_AS_NEW',
  'STORE_INVITED_IDS',
  'TOGGLE_REEL_PLAYER_BLOCK',
  'UPDATE',
  'UPGRADE_PLAN',
]);

export const legacyOpenPresentationEditor = createAction(
  PRESENTATION_EDITOR.OPEN,
  (assetId, defaultTab) => ({ assetId, defaultTab })
);

export const editPresentation = createAction(
  PRESENTATION_EDITOR.EDIT,
  (presentationId) => ({ presentationId })
);

export const setPresentationEditorId = createAction(
  PRESENTATION_EDITOR.SET_CURRENT_EDITOR_ID,
  (presentationId) => ({ presentationId })
);

export const listReviewers = createAction(
  PRESENTATION_EDITOR.LIST_REVIEWERS_FOR_PRESENTATION_ID,
  (page, presentationId) => ({ page, presentationId })
);

export const inviteUsersToPresentation = createAction(
  PRESENTATION_EDITOR.INVITE_USERS,
  (tokens, message, presentationId) => ({ tokens, message, presentationId })
);

export const deleteInvitee = createAction(
  PRESENTATION_EDITOR.DELETE_INVITEE,
  (entityId) => ({ entityId })
);

export const selectPresentationAssets = createAction(
  PRESENTATION_EDITOR.SELECT_ASSETS,
  (assetIds = []) => ({ assetIds })
);

export const setDefaultSessionWatermarkTemplateIdForTeam = createAction(
  PRESENTATION_EDITOR.SET_DEFAULT_TEAM_SESSION_WATERMARK_ID,
  (teamId, defaultSessionWatermarkTemplateId) => ({
    defaultSessionWatermarkTemplateId,
    teamId,
  })
);

export const shareAssetsAsNewPresentation = createAction(
  PRESENTATION_EDITOR.SHARE_ASSETS_AS_NEW,
  (assetIds) => ({ assetIds })
);

export const storeInvitedIds = createAction(
  PRESENTATION_EDITOR.STORE_INVITED_IDS,
  (invitedIds, invitedTotal) => ({ invitedIds, invitedTotal })
);

export const toggleReelPlayerBlock = createAction(
  PRESENTATION_EDITOR.TOGGLE_REEL_PLAYER_BLOCK,
  (isOpen) => ({ isOpen })
);

export const updatePresentation = createAction(
  PRESENTATION_EDITOR.UPDATE,
  (presentationId, presentation) => ({ presentationId, presentation })
);

export const upgradePlan = createAction(
  PRESENTATION_EDITOR.UPGRADE_PLAN,
  (presentationId, feature, source, defaultTab) => ({
    presentationId,
    feature,
    source,
    defaultTab,
  })
);
