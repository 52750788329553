import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { MenuButton, MenuGroup } from '@frameio/vapor';
import { trackButtonClick } from 'analytics';
import { resolutionToResolutionField } from 'utils/downloads';
import { getProxyDownloadButtons } from 'utils/proxyDownload';

function getAvailableDownloadsForAsset(asset) {
  const { transcodes: { resolutions = [] } = {}, downloads } = asset;
  return resolutions.filter((resolution) => {
    const resKey = resolutionToResolutionField[resolution];
    const url = downloads[resKey];
    if (url) return resolution;
    return false;
  });
}

// todo(joelkang - CORE-1201): use downloads object instead of resolutions
export default function DownloadAssetAs({
  asset,
  canDownloadOriginalCleanAsset,
  isForensicFallbackEnabled,
  downloadAsset,
  trackingPage,
  trackingPosition,
}) {
  const {
    transcodes: { original_width: width, original_height: height } = {},
    metadata,
  } = asset;

  const videoCodec = get(metadata, 'blob.video_codec', '');
  const isAssetArchived = !!asset.archived_at;
  const availableResolutions = getAvailableDownloadsForAsset(asset);
  const label = canDownloadOriginalCleanAsset ? 'Proxy' : 'Watermarked Proxy';

  return (
    <React.Fragment>
      {!isAssetArchived && canDownloadOriginalCleanAsset && (
        <MenuGroup label="Original">
          <MenuButton
            data-test-id="original-download-option"
            onSelect={() => {
              downloadAsset(asset.id);
              trackButtonClick(
                'downloaded original',
                trackingPage,
                trackingPosition
              );
            }}
          >
            {`${videoCodec} ${width}x${height}`}
          </MenuButton>
        </MenuGroup>
      )}

      {availableResolutions.length > 0 && (
        <MenuGroup label={label}>
          {getProxyDownloadButtons({
            resolutionsThatHaveDownloads: [...availableResolutions],
            downloadAsset,
            file: asset,
            isForensicFallbackEnabled,
            trackingPage,
            trackingPosition,
          })}
        </MenuGroup>
      )}
    </React.Fragment>
  );
}

DownloadAssetAs.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    transcodes: PropTypes.shape({
      original_width: PropTypes.number.isRequired,
      original_height: PropTypes.number.isRequired,
      resolutions: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    metadata: PropTypes.shape({
      blob: PropTypes.shape({
        video_codec: PropTypes.string,
      }),
    }),
    archived_at: PropTypes.string,
  }).isRequired,
  canDownloadOriginalCleanAsset: PropTypes.bool.isRequired,
  downloadAsset: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export const testExports = {
  getAvailableDownloadsForAsset,
};
