import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import Flex from 'styled-flex-component';
import track from 'analytics';
import { constant, noop } from 'lodash';
import Checkbox, {
  CheckCircle,
  CheckIcon,
} from '@frameio/components/src/styled-components/Checkbox';
import Button from '@frameio/components/src/styled-components/Button';
import TextArea from '@frameio/components/src/styled-components/TextArea';
import UserSearch from 'components/UserSearch/';
import WatermarkDisclaimerBanner from 'components/WatermarkDisclaimerBanner';
import {
  searchUsersStrategy,
  searchGroupsStrategy,
  searchTeamsStrategy,
  searchPendingReviewersStrategy,
} from 'components/UserSearch/strategies';
import LinkAccess, { LINK_ACCESS_OPTIONS } from 'components/LinkAccess';
import { getPublicReviewLinkUrl } from 'URLs';
import ManagePeopleList from './ConnectedLinkSharingManagePeopleList';
import ReviewerPersonRow from './PersonRow/ReviewerPersonRow';
import { SettingsSubHeader } from './ReviewLinkEditor';

// technically the email input is 39px.But the height is 40px in the designs.
const DEFAULT_TEXTAREA_HEIGHT = 39;
const EXPANDED_TEXTAREA_HEIGHT = 106;
const CHARACTER_COUNT_BEFORE_EXPAND = 60;

// Enterprise accounts are allowed a higher message limit on shares
const MAX_MESSAGE_LENGTH = '255';

// presentation pages have a separate max length, see V3FRAME-754
export const ENTERPRISE_MAX_MESSAGE_LENGTH_FOR_REVIEW_LINKS = '2500';

const EXPAND_COLLAPSE_DURATION = 175;
const EXPAND_COLLAPSE_EASE = `linear`; // `cubic-bezier(0.25, 0, 0, 1)`;

const StyledCheckBox = styled(Checkbox)`
  margin-right: ${(p) => p.theme.spacing.units(1)};
  ${CheckCircle} {
    width: ${(p) => p.theme.spacing.units(2)};
    height: ${(p) => p.theme.spacing.units(2)};
    // box-shadow: ${(p) => `inset 0 0 2px ${p.theme.color.dimGray}`};
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.color.accentGrayMedium};
    border-radius: ${(p) => p.theme.radius.medium};
  }
  ${CheckIcon} {
    border-radius: ${(p) => p.theme.radius.medium};
    width: ${(p) => p.theme.spacing.units(2)};
    height: ${(p) => p.theme.spacing.units(2)};
  }
`;

const CheckBoxFlex = styled(Flex)`
  flex-basis: 0%;
  height: 100%;
  color: ${(p) => p.theme.color.dimGray};
  font-size: 12px;
  margin-right: ${(p) => p.theme.spacing.units(3)};
  ${(p) => p.disabled && `opacity: 0.5;`}
  cursor: pointer;

  input {
    cursor: pointer;
  }
`;

const CheckBoxWrapper = styled.div`
  display: inline-block;
`;

/**
 * This only exists because of VQA feedback
 * that optically the alignment of text is off.
 */
const CheckBoxTitle = styled.span`
  margin-top: 1px;
`;

const StyledManagePeopleList = styled(ManagePeopleList)`
  padding-top: ${(p) => p.theme.spacing.tiny};
`;

// properly adjust spacing between the layout
const EmailMessageTextAreaContainer = styled(Flex)`
  display: flex;
  align-items: center;
  transition: all ${EXPAND_COLLAPSE_DURATION}ms ${EXPAND_COLLAPSE_EASE};
  transition-property: height, margin, opacity;
  overflow: hidden;

  ${(p) =>
    `height: ${p.textAreaHeight}px;
    opacity: ${p.textAreaHeight === 0 ? 0 : 1};
    margin: ${p.textAreaHeight === 0 ? 0 : p.theme.spacing.tiny} 0px ${
      p.theme.spacing.tiny
    }`};
`;

const EmailMessageStyledTextArea = styled(TextArea)`
  width: 100%;
  display: block;
  min-height: ${DEFAULT_TEXTAREA_HEIGHT}px;
  height: 100%;
`;

const ShareEmailControls = styled(Flex).attrs(() => ({ justifyBetween: true }))`
  overflow: hidden;
  transition: height ${EXPAND_COLLAPSE_DURATION}ms ${EXPAND_COLLAPSE_EASE};
  height: ${(p) => p.visibleHeight}px;
`;

const ReviewLinkEditorShareForm = ({
  accountId,
  canCustomizeEmailMessages,
  canSharePublicly,
  copyShortUrlToClipboard,
  formik: {
    errors,
    handleChange,
    setFieldValue,
    values: {
      currentMode,
      emailMessage,
      expiresAt,
      id,
      isExpired,
      projectId,
      sendEmail,
      sessionWatermarkId,
      shortUrl,
      showEmailMessage,
      userTokens,
    },
  },
  secureSharingEnabled,
  inviteUsersToReviewLink,
  isEnterprise,
  onChange,
  projectId: project_id,
  reviewLinkId,
  totalInviteCount,
}) => {
  const handleAccessOptionChangeAndSubmit = useCallback(
    (nextValue) => {
      track('review-link-modal-button-clicked', {
        title: nextValue,
        page: 'review_link_modal',
        position: 'link_access',
        modal_version: '2020_01_tabs',
      });
      onChange({ target: { name: 'currentMode', value: nextValue } });
    },
    [onChange]
  );
  const handleCopyShortUrlToClipboard = useCallback(
    (url) => {
      copyShortUrlToClipboard(url);
      track('project-links-page-url-copied', {
        type: 'review_link_modal',
        modal_version: '2020_01_tabs',
        review_id: reviewLinkId,
      });
    },
    [copyShortUrlToClipboard, reviewLinkId]
  );

  // Check for existing length, when you tab toggle:
  const [messageTextAreaHeight, setMessageTextAreaHeight] = useState(
    emailMessage.length > CHARACTER_COUNT_BEFORE_EXPAND
      ? EXPANDED_TEXTAREA_HEIGHT
      : DEFAULT_TEXTAREA_HEIGHT
  );

  return (
    <React.Fragment>
      <SettingsSubHeader>Link access</SettingsSubHeader>
      <LinkAccess
        canSharePublicly={canSharePublicly}
        copyShortUrlToClipboard={handleCopyShortUrlToClipboard}
        currentMode={currentMode}
        expiresAt={expiresAt}
        secureSharingEnabled={secureSharingEnabled}
        isExpired={Boolean(isExpired)}
        onAccessModeSelect={handleAccessOptionChangeAndSubmit}
        // WK-158: https://github.com/Frameio/web-client/pull/8424
        shortUrl={getPublicReviewLinkUrl(id, shortUrl)}
        showSecureSharingTooltip={
          currentMode === LINK_ACCESS_OPTIONS.PRIVATE &&
          (!totalInviteCount || totalInviteCount === 0)
        }
      />
      {currentMode === LINK_ACCESS_OPTIONS.PUBLIC &&
        Boolean(sessionWatermarkId) && <WatermarkDisclaimerBanner />}

      {currentMode !== LINK_ACCESS_OPTIONS.DISABLED && (
        <React.Fragment>
          <SettingsSubHeader>Add people</SettingsSubHeader>
          <UserSearch
            newUserPrompt={(user) => `Invite ${user} to this review link`}
            placeholderText="Enter name or email address"
            setTokens={(tokens) => setFieldValue('userTokens', tokens)}
            tokens={userTokens}
            strategies={[
              searchGroupsStrategy(accountId),
              searchTeamsStrategy(accountId),
              searchUsersStrategy(accountId, project_id),
              searchPendingReviewersStrategy(project_id),
            ]}
            trackEventName="share-link-reviewer-suggestion-clicked"
          />
          {/* TODO [https://frame-io.atlassian.net/browse/RNC-1445] Ideally we'd tell the user
            why their input is invalid along with putting the component into an error state and
            disabling the send button. We could add an error message to the <TextArea /> base
            component, using <TextInput /> as a model. */}
          {userTokens.length > 0 && showEmailMessage ? (
            <EmailMessageTextAreaContainer
              textAreaHeight={messageTextAreaHeight}
            >
              <EmailMessageStyledTextArea
                autoFocus
                name="emailMessage"
                placeholder="Enter your message here..."
                onChange={(evt) => {
                  // before we pass the change evt to the handleChange func
                  // adjust the height as necessary.
                  // check for line breaks regardless of character count:
                  const currentValue = evt.target.value;
                  setMessageTextAreaHeight(
                    currentValue.match(/\r|\n/) ||
                      currentValue.length > CHARACTER_COUNT_BEFORE_EXPAND
                      ? EXPANDED_TEXTAREA_HEIGHT
                      : DEFAULT_TEXTAREA_HEIGHT
                  );
                  handleChange(evt);
                }}
                textAreaHeight={messageTextAreaHeight}
                maxLength={
                  isEnterprise
                    ? ENTERPRISE_MAX_MESSAGE_LENGTH_FOR_REVIEW_LINKS
                    : MAX_MESSAGE_LENGTH
                }
                rows="1"
                error={errors.emailMessage}
                value={emailMessage}
              />
            </EmailMessageTextAreaContainer>
          ) : (
            <EmailMessageTextAreaContainer textAreaHeight={0} />
          )}

          <ShareEmailControls visibleHeight={userTokens.length > 0 ? 32 : 0}>
            {userTokens.length > 0 && (
              <>
                <CheckBoxWrapper>
                  <CheckBoxFlex as="label" inline alignCenter>
                    <StyledCheckBox
                      checked={sendEmail}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setFieldValue('showEmailMessage', false);
                        }
                        setFieldValue('sendEmail', e.target.checked);
                      }}
                    />
                    <CheckBoxTitle>Notify recipients via email</CheckBoxTitle>
                  </CheckBoxFlex>
                  {canCustomizeEmailMessages && (
                    <CheckBoxFlex
                      disabled={!sendEmail}
                      inline
                      as="label"
                      alignCenter
                    >
                      <StyledCheckBox
                        isDisabled={!sendEmail}
                        checked={showEmailMessage}
                        onChange={(e) => {
                          if (sendEmail) {
                            setFieldValue('showEmailMessage', e.target.checked);
                          }
                          track('review-link-modal-button-clicked', {
                            title: 'add_a_message',
                            page: 'review_link_modal',
                            position: 'middle',
                          });
                        }}
                      />
                      <CheckBoxTitle>Add a Message</CheckBoxTitle>
                    </CheckBoxFlex>
                  )}
                </CheckBoxWrapper>
                <Flex alignStart>
                  <Button
                    disabled={Boolean(errors.emailMessage)}
                    onClick={() => {
                      inviteUsersToReviewLink(
                        userTokens,
                        sendEmail,
                        sendEmail && showEmailMessage ? emailMessage : null
                      );
                      track('review-link-modal-button-clicked', {
                        title: 'add',
                        page: 'review_link_modal',
                        position: 'middle',
                      });
                    }}
                    primary
                    type="button"
                  >
                    Add
                  </Button>
                </Flex>
              </>
            )}
          </ShareEmailControls>

          <StyledManagePeopleList
            className="manage-people"
            projectId={projectId}
            makeAllRowsVisible
            renderHeader={(personCount) => (
              <h4>
                Shared with {personCount}{' '}
                {personCount === 1 ? 'person' : 'people'}
              </h4>
            )}
            renderEmptyList={constant(<React.Fragment />)}
            scrollTop={0}
            setScrollTop={noop}
            shouldShowFilter={false}
            trackingEvent="review-link-modal-button-clicked"
            trackingPage="review_link_modal"
            trackingPosition="invite_people"
            trackingTitle="share_link"
          >
            {(entityId) => <ReviewerPersonRow entityId={entityId} />}
          </StyledManagePeopleList>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

ReviewLinkEditorShareForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  canSharePublicly: PropTypes.bool.isRequired,
  copyShortUrlToClipboard: PropTypes.func.isRequired,
  secureSharingEnabled: PropTypes.bool.isRequired,
  inviteUsersToReviewLink: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.shape({
      emailMessage: PropTypes.string,
    }),
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.shape({
      currentMode: PropTypes.oneOf(Object.values(LINK_ACCESS_OPTIONS)),
      emailMessage: PropTypes.string.isRequired,
      expiresAt: PropTypes.string,
      id: PropTypes.string.isRequired,
      isExpired: PropTypes.bool.isRequired,
      sendEmail: PropTypes.boolean,
      sessionWatermarkId: PropTypes.string,
      shortUrl: PropTypes.string.isRequired,
      showEmailMessage: PropTypes.bool.isRequired,
      userTokens: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
  projectId: PropTypes.string.isRequired,
  reviewLinkId: PropTypes.string.isRequired,
  totalInviteCount: PropTypes.number,
};

export default connect(ReviewLinkEditorShareForm);
