import React from 'react';
import { get, snakeCase, mapKeys } from 'lodash';
import {
  put,
  takeLatest,
  select,
  fork,
  spawn,
  call,
  take,
  takeEvery,
} from 'redux-saga/effects';
import {
  getPresentation,
  createPresentation,
  updatePresentation as updatePresentationSaga,
  deleteReviewerFromPresentation as deleteReviewerFromPresentationCoreSaga,
  deletePendingReviewerFromPresentation as deletePendingReviewerFromPresentationCoreSaga,
} from '@frameio/core/src/presentations/sagas';
import { listSessionWatermarkTemplatesForPresentation } from '@frameio/core/src/sessionWatermarkTemplate/sagas';
import {
  presentationEntitySelector,
  presentationEntityByAssetIdSelector,
} from '@frameio/core/src/presentations/selectors';
import {
  accountEntityForPresentationIdSelector,
  accountIdForPresentationIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import {
  batchAddReviewersToPresentation as batchAddReviewersToPresentationCoreSaga,
  listPendingAndRegularReviewersForPresentationId as listPendingAndRegularReviewersForPresentationIdCoreSaga,
} from '@frameio/core/src/reviewers/sagas';
import { updateTeam as updateTeamSaga } from '@frameio/core/src/teams/sagas';
import track from 'analytics';
import { allFeatures } from 'planFeatures';
import { openModal, closeModal, MODAL } from 'components/Modal/actions';
import { BLOG_LAYOUT, REEL_LAYOUT } from 'components/Presentation/layouts';
import { openSelectPlanFlowModal } from 'components/SelectPlanFlow/actions';
import { didUpgradePlan } from 'components/SelectPlanFlow/sagas';
import PresentationEditor from 'components/PresentationEditor';
import { SMALL } from 'utils/mediaQueries';
import {
  newSharingModalEnabled,
  watermarkIdEndUserOverrideEnabled,
} from 'utils/featureFlags';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { permittedActionsForAccountSelector } from 'selectors/permissions';
import { isAccountEnterpriseSelector } from 'selectors/accounts';
import { getTokensToSubmit, getTrackingData } from '../UserSearch/utils';
import { calculateInvitedUsersCount } from '../UserSearch/sagas';
import { ENTITY_TYPE } from '../AuthGate/AuthGate';
import {
  assetsForPresentationSelector,
  currentPresentationIdSelector,
  invitedEntitiesForItemIdSelector,
  invitedTotalForItemIdSelector,
  NEW_PRESENTATION_ID,
} from './selectors';
import { invitedEntityAndTypeForId } from '../ReviewLinkEditor/selectors';
import {
  PRESENTATION_EDITOR,
  editPresentation,
  toggleReelPlayerBlock,
  setPresentationEditorId,
  storeInvitedIds,
} from './actions';
import SecurePresentationEditor from './SecurePresentationEditor';

const { reelPlayer, customBrandedPresentations } = allFeatures;
const MAX_MODAL_SIZE = 1460;

function* getPresentationEntityData(assetId) {
  const { failure } = yield call(getPresentation, assetId);

  if (failure) {
    // "If the presentation does not exist, it will be necessary to create it."
    // - Voltaire https://simple.wikiquote.org/wiki/Voltaire#cite_ref-3
    yield call(createPresentation, assetId);
  }

  return yield select(presentationEntityByAssetIdSelector, {
    assetId,
  });
}

export function* legacyOpenPresentationEditor({
  payload: { assetId, defaultTab },
}) {
  const presentation = yield call(getPresentationEntityData, assetId);
  const assetIds = get(presentation, 'assets');
  const vanityId = get(presentation, 'vanity');
  const hydratedAssets = yield select(assetsForPresentationSelector, {
    assetIds,
  });
  const isEnterprise = yield select(isAccountEnterpriseSelector);
  const isNewSharingModalEnabled = yield select(newSharingModalEnabled);
  const isSmallScreen = window.matchMedia(SMALL).matches;
  const height = isSmallScreen ? '80vh' : '96vh';

  yield spawn(track, 'presentation-link-modal-shown', {
    modal_version: isNewSharingModalEnabled
      ? '2020_01_tabs'
      : '2019_07_no_tabs',
  });

  if (yield select(watermarkIdEndUserOverrideEnabled)) {
    yield fork(listSessionWatermarkTemplatesForPresentation, presentation.id);
  }

  if (isNewSharingModalEnabled) {
    yield put(
      openModal(
        <SecurePresentationEditor
          assetId={assetId}
          defaultTab={defaultTab}
          isEnterprise={isEnterprise}
          vanityId={vanityId}
        />,
        {
          enableFocusLock: false,
          style: isSmallScreen
            ? {
                width: '100%',
                height: '100%',
                maxHeight: 'none',
                margin: 0,
                borderRadius: 0,
              }
            : {
                width: '96vw',
                height: '96vh',
                margin: 40,
                maxHeight: MAX_MODAL_SIZE,
                maxWidth: MAX_MODAL_SIZE,
              },
        }
      )
    );
  } else {
    yield put(
      openModal(
        <PresentationEditor
          assets={hydratedAssets}
          vanityId={vanityId}
          assetId={assetId} // passes top level assetId down for folders & versionStacks
          defaultTab={defaultTab}
        />,
        // focusLock traps first focusable item in the modal bc popover is rendered in portal
        {
          enableFocusLock: false,
          maxHeight: MAX_MODAL_SIZE,
          style: {
            width: '96vw',
            margin: 40,
            maxWidth: MAX_MODAL_SIZE,
            height,
          },
        }
      )
    );
  }

  if (yield take(MODAL.CLOSE)) {
    yield spawn(track, 'presentation-link-modal-closed', {
      modal_version: isNewSharingModalEnabled
        ? '2020_01_tabs'
        : '2019_07_no_tabs',
    });
  }
}

function* inviteUsersToPresentation({
  payload: { tokens, message, presentationId },
}) {
  const isNewSharingModalEnabled = yield select(newSharingModalEnabled);
  const tokensToSubmit = getTokensToSubmit(tokens);

  const serverParams = {
    batch: tokensToSubmit,
    default_message: message,
  };

  const { failure } = yield call(
    batchAddReviewersToPresentationCoreSaga,
    presentationId,
    serverParams
  );
  if (failure) {
    if (tokens.length > 50) {
      yield put(
        showErrorToast({
          header: 'You cannot share with more than 50 people at a time',
        })
      );
    } else {
      yield put(
        showErrorToast({
          header: 'Something went wrong while inviting reviewers.',
          subHeader: 'Please try again.',
        })
      );
    }
    return;
  }

  const invitedUsersCount = yield call(calculateInvitedUsersCount, tokens);

  yield put(
    showSuccessToast({
      header: `Shared with ${invitedUsersCount} ${
        invitedUsersCount === 1 ? 'person' : 'people'
      }`,
    })
  );
  yield put(closeModal());
  yield put(setPresentationEditorId(null));
  yield spawn(track, 'share-presentation-modal-submitted', {
    ...getTrackingData(tokens, ENTITY_TYPE.PRESENTATION),
    modal_version: isNewSharingModalEnabled
      ? '2020_01_tabs'
      : '2019_07_no_tabs',
  });
}

function* listInvitedEntitiesForPresentation({
  payload: { presentationId, page },
}) {
  const data = yield call(
    listPendingAndRegularReviewersForPresentationIdCoreSaga,
    presentationId,
    { page }
  );
  if (data.failure) return;

  const loadedIds = yield select(invitedEntitiesForItemIdSelector);
  const fetchedIds = data.success.result;
  const reviewerIds = (loadedIds || []).concat(fetchedIds);

  const reviewerCount = data.success.headers.total;

  yield put(storeInvitedIds(reviewerIds, reviewerCount));
}

function* deleteInviteeFromPresentation({ payload: { entityId } }) {
  const presentationId = yield select(currentPresentationIdSelector);

  const { type } = yield select(invitedEntityAndTypeForId, { entityId });

  const deletionSaga =
    type === 'reviewer'
      ? deleteReviewerFromPresentationCoreSaga
      : deletePendingReviewerFromPresentationCoreSaga;

  const { success } = yield call(deletionSaga, presentationId, entityId);

  // TODO(Scott) we need non-happy path support here.
  if (success) {
    const invitedCount = yield select(invitedTotalForItemIdSelector);
    let invitedIds = yield select(invitedEntitiesForItemIdSelector);
    invitedIds = invitedIds.filter((i) => i !== entityId);
    yield put(storeInvitedIds(invitedIds, invitedCount - 1));
  }
}

function* updatePresentation({ payload: { presentationId, presentation } }) {
  // TODO(marvin): update optimistically/rollback if failed

  // When switching to reel layout, show the block if necessary
  if (presentation.layout === REEL_LAYOUT) {
    const { id: accountId } = yield select(
      accountEntityForPresentationIdSelector,
      { presentationId }
    );
    const { canUseReelPlayer } = yield select(
      permittedActionsForAccountSelector,
      { accountId }
    );

    if (!canUseReelPlayer) {
      yield put(toggleReelPlayerBlock(true));
      yield spawn(track, 'action-blocked', { limit: reelPlayer });
      return;
    }

    // Switching back to the blog player toggles off the overlay
  } else if (presentation.layout === BLOG_LAYOUT) {
    yield put(toggleReelPlayerBlock(false));
  }

  const { failure } = yield call(
    updatePresentationSaga,
    presentationId,
    presentation
  );
  if (failure) {
    yield put(
      showErrorToast({
        header:
          'Something went wrong while updating the presentation. Please try again.',
      })
    );
  }
}

function* upgradePlan({
  payload: { presentationId, feature, source, defaultTab = 'settings' },
}) {
  const accountId = yield select(accountIdForPresentationIdSelector, {
    presentationId,
  });
  const { asset_id: assetId } = yield select(presentationEntitySelector, {
    presentationId,
  });

  const action = {
    payload: {
      assetId,
      defaultTab,
    },
  };

  yield put(
    openSelectPlanFlowModal(accountId, {
      source,
      feature,
    })
  );

  const planUpgraded = yield call(didUpgradePlan);

  if (!planUpgraded) {
    yield call(legacyOpenPresentationEditor, action);
    return;
  }

  // Actually save the reel layout option. If this call fails (which shouldn't
  // in normal operations), `toggleReelPlayerBlock(false)` below will revert to
  // the blog layout. It's then up to the user to retry by clicking on the reel
  // layout button again.
  const { canUseReelPlayer, canUseCustomBrandedPresentations } = yield select(
    permittedActionsForAccountSelector,
    { accountId }
  );
  if (canUseReelPlayer && planUpgraded) {
    yield call(updatePresentationSaga, presentationId, {
      layout: REEL_LAYOUT,
    });
    yield put(toggleReelPlayerBlock(false));
    yield spawn(track, 'feature-unlocked', { feature: reelPlayer });
  }

  if (canUseCustomBrandedPresentations && planUpgraded) {
    yield spawn(track, 'feature-unlocked', {
      feature: customBrandedPresentations,
    });
  }

  yield call(legacyOpenPresentationEditor, action);
}

function* selectPresentationAssets() {
  // TODO(anna): Setting of presentation id will be decoupled from asset selection
  yield put(editPresentation(NEW_PRESENTATION_ID));
}

function* setDefaultSessionWatermarkTemplateIdForTeam(action) {
  const {
    payload: { teamId, ...actionPayload },
  } = action;

  const toSnakeCasePayload = (obj) => mapKeys(obj, (_, k) => snakeCase(k));
  const payload = toSnakeCasePayload(actionPayload);
  const result = yield call(updateTeamSaga, teamId, payload);

  if (result.failure) {
    yield put(
      showErrorToast({
        header:
          'Something went wrong setting the default template for this team',
      })
    );
  }
}

export default [
  takeLatest(PRESENTATION_EDITOR.OPEN, legacyOpenPresentationEditor),
  takeLatest(PRESENTATION_EDITOR.INVITE_USERS, inviteUsersToPresentation),
  takeEvery(
    PRESENTATION_EDITOR.LIST_REVIEWERS_FOR_PRESENTATION_ID,
    listInvitedEntitiesForPresentation
  ),
  takeLatest(PRESENTATION_EDITOR.SELECT_ASSETS, selectPresentationAssets),
  takeLatest(
    PRESENTATION_EDITOR.SET_DEFAULT_TEAM_SESSION_WATERMARK_ID,
    setDefaultSessionWatermarkTemplateIdForTeam
  ),
  takeLatest(PRESENTATION_EDITOR.UPDATE, updatePresentation),
  takeLatest(PRESENTATION_EDITOR.UPGRADE_PLAN, upgradePlan),
  takeLatest(PRESENTATION_EDITOR.DELETE_INVITEE, deleteInviteeFromPresentation),
];

export const testExports = {
  getPresentationEntityData,
  legacyOpenPresentationEditor,
  inviteUsersToPresentation,
  listInvitedEntitiesForPresentation,
  deleteInviteeFromPresentation,
  updatePresentation,
  upgradePlan,
};
