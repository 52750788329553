import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import { isNumber } from 'lodash';
import Media from 'react-media';
import { RESOLUTION_VALUES } from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';
import HotkeyButton from '@frameio/components/src/styled-components/HotkeyButton';

import { SMALL, MEDIUM_UP } from 'utils/mediaQueries';
import { isIOS } from 'utils/devices';

import PlayIcon from '@frameio/components/src/svgs/icons/16/play.svg';
import PauseIcon from '@frameio/components/src/svgs/icons/16/pause.svg';
import FullscreenIcon from '@frameio/components/src/svgs/icons/16/fullscreen.svg';
import Player360Icon from '@frameio/components/src/svgs/raw/ic-player-360-16px.svg';

import VolumeControl from 'components/PlayerControlBar/VolumeControl';
import PlayerBarButton from 'components/PlayerControlBar/PlayerBarButton';
import TimeDisplay from 'components/PlayerControlBar/TimeDisplay';
import LoopButton from 'components/PlayerControlBar/LoopButton';
import FrameGuidePickerButton from 'components/PlayerControlBar/FrameGuidePickerButton';
import CustomPlayback from 'components/PlayerControlBar/CustomPlayback';
import ResolutionPickerButton from 'components/PlayerControlBar/ResolutionPickerButton';
import { trackViewer } from 'analytics';
import Hls from '@frameio/components/src/lib/hlsjs';
import { MobilePlaybackResolution } from 'components/PlayerControlBar/MobilePlaybackResolution';

export const TOOLTIP_TITLES = {
  PLAYING: 'Pause [K]',
  PAUSED: 'Play [K]',
  LOOP: 'Loop',
  PLAYBACK_SPEED: 'Playback Speed',
  MUTED: 'Unmute [M]',
  UNMUTED: 'Mute [M]',
  TIME_DISPLAY: 'Time Format',
  RESOLUTION: 'Quality',
  TURNSTYLE: 'Turnstyle Orientation [V]',
  FRAME_GUIDES: 'Guides [G]',
  PLAYER_360: '360 Player',
  FULLSCREEN: 'Fullscreen [F]',
};

const Wrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.color.coolBlack};
  height: ${({ theme }) => theme.spacing.units(5)};
  padding: 0 ${(p) => p.theme.spacing.units(1)};

  .playbar-tooltip {
    ${(p) =>
      p.isPopoverShowing &&
      `
        visibility: hidden;
        pointer-events: none;
      `}
  }
`;

const Section = styled(Flex)`
  @media ${SMALL} {
    &:nth-child(2) {
      flex-grow: 1;
    }
  }

  @media ${MEDIUM_UP} {
    flex: 1;
  }
`;

const PlayerControlBar = ({
  canUse4KPlayback,
  clearRange,
  customPlayback,
  frameGuide,
  fullScreen,
  fwmFrameRateSupported,
  hlsInstance,
  loop,
  media,
  mediaEl,
  playPause,
  player360,
  resolutionPicker,
  timeDisplay,
  tooltips,
  // REMOVED FOR CORE-5176
  // turnStyle,
  volume,
}) => {
  const [isPopoverShowing, setIsPopoverShowing] = useState(undefined);

  const selectedAutoResolution =
    (resolutionPicker?.selectedResolution === 'Auto' &&
      hlsInstance?.levels?.[hlsInstance?.currentLevel]?.height) ||
    undefined;

  let hasRange;
  if (clearRange) {
    hasRange =
      clearRange.loopRange &&
      isNumber(clearRange.loopRange.start) &&
      isNumber(clearRange.loopRange.duration) &&
      clearRange.loopRange.duration > 0;
  }

  // check if fullScreen is available before toggling
  const handleFullScreenClick = (e) => {
    if (fwmFrameRateSupported && fullScreen) fullScreen.toggleFullscreen();
    !fwmFrameRateSupported && e.currentTarget.blur();
    trackViewer('full-screen-button-clicked');
  };

  // REMOVED FOR CORE-5167
  // let viewTypes;
  // if (turnStyle) {
  //   viewTypes = getViewTypesFromLayout(turnStyle.assetLayout);
  // }

  return (
    <Wrapper isPopoverShowing={isPopoverShowing} alignCenter>
      <Section justifyStart>
        {playPause && (
          <PlayerBarButton
            aria-label="Play"
            aria-pressed={!media.paused}
            onClick={(evt) => {
              playPause.togglePlaying();

              /*
               * only track "clicks" not keyboard events
               * (which can be fired via quicklook if play button is focused.).
               */
              if (evt.type === 'keypress') return;
              trackViewer('play-pause-button-clicked', {
                state: media.paused ? 'play' : 'paused',
              });
            }}
            icon={media.paused ? <PlayIcon /> : <PauseIcon />}
            tooltipTitle={media.paused ? tooltips.PAUSED : tooltips.PLAYING}
          />
        )}
        {loop && (
          <LoopButton
            isLooping={media.loop}
            toggleLoop={loop.toggleLoop}
            tooltipTitle={tooltips.LOOP}
          />
        )}
        {customPlayback && (
          <Media
            query={MEDIUM_UP}
            render={() => (
              <CustomPlayback
                defaultPlaybackRate={customPlayback.defaultPlaybackRate}
                effectivePlaybackRate={customPlayback.effectivePlaybackRate}
                onDefaultPlaybackRateChange={
                  customPlayback.onDefaultPlaybackRateChange
                }
                setIsPopoverShowing={setIsPopoverShowing}
                tooltipTitle={tooltips.PLAYBACK_SPEED}
              />
            )}
          />
        )}
        {volume && !isIOS && (
          <Media
            query={MEDIUM_UP}
            render={() => (
              <VolumeControl
                isMuted={media.muted}
                changeVolume={volume.changeVolume}
                mute={volume.mute}
                unmute={volume.unmute}
                tooltips={tooltips}
              />
            )}
          />
        )}
      </Section>
      <Section justifyCenter alignCenter>
        {hasRange && clearRange ? (
          <HotkeyButton
            onClick={clearRange.onClearRange}
            text="Clear playback range"
            hotkeyText="ESC"
          />
        ) : (
          timeDisplay && (
            <TimeDisplay
              audioCodec={timeDisplay.audioCodec}
              displayType={timeDisplay.currentTimeDisplayType}
              frameRate={timeDisplay.frameRate}
              isHlsPlayerEnabled={timeDisplay.isHlsPlayerEnabled}
              media={media}
              mediaEl={mediaEl}
              setTimeDisplay={timeDisplay.setTimeDisplay}
              timecodeIn={timeDisplay.startTimecode}
              totalFrames={timeDisplay.totalFrames}
              setIsPopoverShowing={setIsPopoverShowing}
              tooltipTitle={tooltips.TIME_DISPLAY}
            />
          )
        )}
      </Section>
      <Section justifyEnd>
        {resolutionPicker && resolutionPicker.selectedResolution && (
          <Media
            query={MEDIUM_UP}
            render={() => (
              <ResolutionPickerButton
                canUse4KPlayback={canUse4KPlayback}
                isHlsPlayerEnabled={resolutionPicker.isHlsPlayerEnabled}
                assetResolutions={resolutionPicker.assetResolutions}
                selectedAutoResolution={selectedAutoResolution}
                selectedResolution={resolutionPicker.selectedResolution}
                selectResolution={resolutionPicker.selectResolution}
                setIsPopoverShowing={setIsPopoverShowing}
                tooltipTitle={tooltips.RESOLUTION}
              />
            )}
          />
        )}
        {/* REMOVED FOR CORE-5176  {viewTypes && turnStyle && (
          <Media
            query={MEDIUM_UP}
            render={() => (
              <TurnstylePickerButton
                onViewTypeChange={(selectedViewType) => {
                  turnStyle.onViewTypeChange(selectedViewType);
                  track('turnstyle-picker-clicked', {
                    selected_view_type: selectedViewType,
                    player_type: trackingPlayerType,
                  });
                }}
                selectedViewType={turnStyle.selectedViewType}
                setIsPopoverShowing={setIsPopoverShowing}
                viewTypes={viewTypes}
                tooltipTitle={tooltips.TURNSTYLE}
              />
            )}
          />
        )} */}
        {frameGuide && (
          <Media
            query={MEDIUM_UP}
            render={() => (
              <FrameGuidePickerButton
                activeFrameGuide={frameGuide.activeFrameGuide}
                frameGuides={frameGuide.frameGuides}
                isFrameGuideMaskOn={frameGuide.isFrameGuideMaskOn}
                selectFrameGuide={frameGuide.selectFrameGuide}
                toggleFrameGuideMask={frameGuide.toggleFrameGuideMask}
                setIsPopoverShowing={setIsPopoverShowing}
                tooltipTitle={tooltips.FRAME_GUIDES}
              />
            )}
          />
        )}
        {player360 && (
          <PlayerBarButton
            aria-label="360 player toggle"
            aria-pressed={false}
            onClick={player360.toggle360}
            icon={<Player360Icon />}
            isSelected={player360.is360}
            tooltipTitle={tooltips.PLAYER_360}
          />
        )}
        {(customPlayback || resolutionPicker?.selectedResolution) && (
          <Media
            query={SMALL}
            render={() => (
              <MobilePlaybackResolution
                canUse4KPlayback={canUse4KPlayback}
                customPlayback={customPlayback}
                resolutionPicker={resolutionPicker}
                selectedAutoResolution={selectedAutoResolution}
                setIsPopoverShowing={setIsPopoverShowing}
              />
            )}
          />
        )}
        {fullScreen && (
          <PlayerBarButton
            aria-label="Full screen toggle"
            aria-pressed={false}
            onClick={(e) => handleFullScreenClick(e)}
            icon={<FullscreenIcon />}
            tooltipTitle={tooltips.FULLSCREEN}
          />
        )}
      </Section>
    </Wrapper>
  );
};

PlayerControlBar.defaultProps = {
  clearRange: null,
  customPlayback: null,
  frameGuide: null,
  fullScreen: null,
  fwmFrameRateSupported: true,
  loop: null,
  media: null,
  playPause: null,
  resolutionPicker: null,
  timeDisplay: null,
  tooltips: TOOLTIP_TITLES,
  turnStyle: null,
  volume: null,
};

PlayerControlBar.propTypes = {
  clearRange: PropTypes.exact({
    onClearRange: PropTypes.func.isRequired,
    loopRange: PropTypes.shape({
      start: PropTypes.number,
      duration: PropTypes.number,
    }),
  }),
  customPlayback: PropTypes.exact({
    defaultPlaybackRate: PropTypes.number.isRequired,
    effectivePlaybackRate: PropTypes.number.isRequired,
    onDefaultPlaybackRateChange: PropTypes.func.isRequired,
  }),
  frameGuide: PropTypes.exact({
    activeFrameGuide: PropTypes.string,
    frameGuides: PropTypes.arrayOf(PropTypes.string),
    isFrameGuideMaskOn: PropTypes.bool,
    toggleFrameGuideMask: PropTypes.func,
    selectFrameGuide: PropTypes.func,
  }),
  fullScreen: PropTypes.exact({
    toggleFullscreen: PropTypes.func.isRequired,
    getIsInFullscreen: PropTypes.func,
  }),
  hlsInstance: PropTypes.instanceOf(Hls),
  loop: PropTypes.exact({
    toggleLoop: PropTypes.func.isRequired,
  }),
  media: PropTypes.shape({
    currentTime: PropTypes.number,
    duration: PropTypes.number,
    muted: PropTypes.bool,
    loop: PropTypes.bool,
    paused: PropTypes.bool,
  }).isRequired,
  mediaEl: PropTypes.instanceOf(Element),
  playPause: PropTypes.exact({
    togglePlaying: PropTypes.func.isRequired,
  }),
  player360: PropTypes.exact({
    is360: PropTypes.bool,
    toggle360: PropTypes.func.isRequired,
  }),
  resolutionPicker: PropTypes.exact({
    assetResolutions: PropTypes.arrayOf(
      PropTypes.shape({
        resolution: PropTypes.oneOf(RESOLUTION_VALUES),
      })
    ),
    isHlsPlayerEnabled: PropTypes.bool,
    selectedResolution: PropTypes.oneOf(RESOLUTION_VALUES),
    selectResolution: PropTypes.func,
  }),
  timeDisplay: PropTypes.exact({
    currentTimeDisplayType: PropTypes.string.isRequired,
    audioCodec: PropTypes.string,
    frameRate: PropTypes.number.isRequired,
    isHlsPlayerEnabled: PropTypes.bool,
    setTimeDisplay: PropTypes.func.isRequired,
    startTimecode: PropTypes.string,
    totalFrames: PropTypes.number.isRequired,
  }),
  tooltips: PropTypes.exact({
    PLAYING: PropTypes.string,
    PAUSED: PropTypes.string,
    LOOP: PropTypes.string,
    PLAYBACK_SPEED: PropTypes.string,
    MUTED: PropTypes.string,
    UNMUTED: PropTypes.string,
    TIME_DISPLAY: PropTypes.string,
    PLAYER_360: PropTypes.string,
    RESOLUTION: PropTypes.string,
    TURNSTYLE: PropTypes.string,
    FRAME_GUIDES: PropTypes.string,
    FULLSCREEN: PropTypes.string,
  }),
  turnStyle: PropTypes.exact({
    assetLayout: PropTypes.object,
    onViewTypeChange: PropTypes.func.isRequired,
    selectedViewType: PropTypes.string,
  }),
  volume: PropTypes.exact({
    changeVolume: PropTypes.func,
    mute: PropTypes.func,
    unmute: PropTypes.func,
  }),
};

export default PlayerControlBar;
